.grow { 
    transition: all .2s ease-in-out; 
}

.grow:hover { 
    transform: scale(1.09); 
}

.major {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    display: flex;
    margin: 30px 20px 30px 20px;
    flex-direction: column;
    align-items: center;
    /* height: 500px; */
    flex: 1;
}

.small {
    display: flex;
    font-size: 25px;
    font-weight: 400;
    color: #547048;
    padding: 20px;
    width: 75%;
    text-align: center;
}

.divi {
    width: 30%;
    height: 7px;
    border-radius: 4px;
    display: flex;
    background-color:#547048;
}

.padding {
    padding: 20px;
}